import React, { Fragment, useContext, useEffect } from 'react';
import axios from 'axios';
import getCountryIso3 from 'country-iso-2-to-3';
import { eeaMember } from 'is-european';
import { observer } from 'mobx-react';
import { StoreContext } from '../../App';
import { steps } from '../../../constants/steps';
import { onfidoLocales } from '../../../constants/languages/onfidoLocales';
import { onfidoLocalesNew } from '../../../constants/languages/onfidoLocalesNew';
import { getApiOrigin } from '../../../methods/getApiOrigin';
import { flowDefault, flowRemediation } from '@s/common/defaults/clientConfigDefaults';

export interface DocumentTypesInterface {
  passport: boolean;
  driving_licence: boolean;
  national_identity_card: boolean;
}

export const Onfido: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { sdkToken, flow, forceMobile, onfidoNew } = store.ClientState;

  const mountOnfido = (): void => {
    const onfido = (window as any).Onfido;

    const documentTypesOnfidoOld = {
      passport: store.AppConfig.onfidoConfig.documentTypes.passport,
      // eslint-disable-next-line @typescript-eslint/camelcase
      driving_licence: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      national_identity_card: store.AppConfig.onfidoConfig.documentTypes.national_identity_card,
    };

    const documentTypesOnfidoNew: {
      passport: boolean;
      driving_licence: boolean;
      national_identity_card: boolean | { country: string };
      residence_permit?: boolean | { country: string };
    } = {
      passport: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      driving_licence: false,
      // eslint-disable-next-line @typescript-eslint/camelcase
      national_identity_card: {
        country: getCountryIso3(store.ClientState.selectedCountry.toUpperCase()),
      },
    };

    if (store.ClientState.selectedCountry === 'NL') {
      // eslint-disable-next-line @typescript-eslint/camelcase
      documentTypesOnfidoNew.residence_permit = {
        country: getCountryIso3(store.ClientState.selectedCountry.toUpperCase()),
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
      documentTypesOnfidoNew.residence_permit = false;
    }

    if (eeaMember(store.ClientState.selectedCountry.toUpperCase())) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      documentTypesOnfidoNew.national_identity_card = {
        country: getCountryIso3(store.ClientState.selectedCountry.toUpperCase()),
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
      documentTypesOnfidoNew.national_identity_card = false;
    }

    if (sdkToken) {
      onfido.init({
        token: sdkToken,
        containerId: 'onfido-mount',
        steps: [
          {
            type: 'document',
            options: {
              documentTypes: flow === flowDefault && onfidoNew === '1' ? documentTypesOnfidoNew : documentTypesOnfidoOld,
              forceCrossDevice: forceMobile,
            },
          },
          'complete',
        ],
        language: {
          phrases: flow === flowDefault && onfidoNew === '1' ? onfidoLocalesNew.acquisition : onfidoLocales.remediation,
        },
        smsNumberCountryCode: 'NL',
        onComplete: async () => {
          try {
            const response = await axios.request({
              method: 'POST',
              url: `${getApiOrigin()}/onfido/complete`,
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
              data: {},
            });

            if (response.data) {
              flow !== flowRemediation && store.AppState.changePage('uploadid', 4);
              store.AppState.changeStep({ step: 0, label: '', status: '' });
              store.AppState.setSteps([
                { ...steps[0], status: 'done' },
                { ...steps[1], status: 'done' },
                { ...steps[2], status: 'done' },
              ]);
              if (flow !== flowDefault) {
                setTimeout(() => {
                  document.location.href = `${getApiOrigin()}/complete`;
                }, 2000);
              }
            }
          } catch (e) {
            if (e.response?.status === 403) {
              store.AppState.changePage('error', 1);
            } else {
              console.log(e);
            }
          }
        },
        disableAnalytics: true,
      });
    }
  };

  useEffect(() => {
    mountOnfido();
  }, []);

  return (
    <Fragment>
      <div className={`onfido ${flow === flowRemediation && 'onfido-rem'}`} id="onfido-mount" />
    </Fragment>
  );
});
