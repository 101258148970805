import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';

import { steps } from '../../constants/steps';
import { Modal } from '../modal/component';
import { getApiOrigin } from '../../methods/getApiOrigin';
import { SelectInput } from '../selectInput/component';
import { StoreContext } from '../App';
import { default as idinLogo } from '../../assets/idin_logo.svg';
import { flowDefault } from '@s/common/defaults/clientConfigDefaults';
import { getQueryVariable } from '../../methods/getQueryVariable';

export const BankSelection: React.FC = observer(() => {
  const [selectValue, setSelectValue] = useState('');
  const [list, setList] = useState([]);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const store = useContext(StoreContext);
  const { translations } = store.AppConfig;
  const { pcn, clic, flow, attemptId } = store.ClientState;

  const handleListData = (listData: any): void => {
    const output = Object.keys(listData).map((category) => {
      return {
        name: category,
        values: listData[category].map((value: { issuerName: string; issuerID: string }) => {
          return {
            name: value.issuerName,
            value: value.issuerID,
          };
        }),
      };
    });

    setList(output);
  };

  const handleSubmit = (): void => {
    if (selectValue && selectValue.length) {
      window.location.href = `${getApiOrigin()}/proceed/eid/${selectValue}`;
    }
  };

  const handleSkipIdin = async (): Promise<void> => {
    try {
      await axios.request({
        method: 'POST',
        url: `${getApiOrigin()}/idin/skipped`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      });
    } catch (e) {
      if (e.response?.status === 403) {
        store.AppState.changePage('error', 1);
      } else {
        console.log(e);
      }
    }
  };

  const handleGoToOnfido = async (): Promise<void> => {
    try {
      await handleSkipIdin();
      setInfoModalVisible(false);

      const onfidoNew = getQueryVariable('onfido_new');

      store.AppConfig.setOnfidoShowLoading(false);
      store.AppState.changePage('uploadid', 1);
      store.AppState.setSteps([steps[0], { ...steps[1], status: 'done' }, { ...steps[2], status: '' }]);
      if (pcn !== '') {
        window.history.pushState(
          '',
          '',
          `/?step=onfido-no-loader&pcn=${pcn}&attemptId=${attemptId}flow=${flow}${
            onfidoNew && onfidoNew.length ? '&onfido_new=1&force_mobile=true' : ''
          }`,
        );
      }
      if (clic !== '') {
        window.history.pushState(
          '',
          '',
          `/?step=onfido-no-loader&clic=${clic}&attemptId=${attemptId}&flow=${flow}${
            onfidoNew && onfidoNew.length ? '&onfido_new=1&force_mobile=true' : ''
          }`,
        );
      }
    } catch (e) {
      console.log(e);
      store.AppState.changePage('error', 1);
    }
  };

  useEffect(() => {
    const fetchList = async (): Promise<void> => {
      try {
        const result = await axios.request({
          method: 'GET',
          url: `${getApiOrigin()}/banks`,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {},
        });
        handleListData(result.data);
      } catch (e) {
        store.AppState.changePage('error', 1);
      }
    };

    fetchList();
  }, []);

  const { heading, loginToYourBank, pleaseChoseYourBank, modal, bankNotListed } = translations.pageBankSelection;

  return (
    <>
      <div className="page page--bank-selection">
        {flow !== flowDefault ? (
          <div className="idin-logo--container">
            <img className="idin-logo--image" src={idinLogo} />
          </div>
        ) : (
          ''
        )}
        <h1>{heading}</h1>
        <div className="line centered subtitle">
          <p className="text">{loginToYourBank}</p>
        </div>
        <div className="bank-select--wrapper">
          <SelectInput
            onChange={(item: { value: string }): void => {
              setSelectValue(item.value);
            }}
            categories={list}
            placeholder={pleaseChoseYourBank}
          />
          <div className="line centered">
            <button className="btn" disabled={!selectValue.length} onClick={(): void => handleSubmit()}>
              {translations.continueLabel}
            </button>
            <a href="#" onClick={(): void => setInfoModalVisible(true)}>
              {bankNotListed}
            </a>
          </div>
        </div>
      </div>
      <Modal setModalVisible={setInfoModalVisible} modalVisible={infoModalVisible}>
        <div className="modal--bank-selection">
          <h2>{modal.heading}</h2>
          <div className="line centered">
            <p>{modal.text}</p>
          </div>
          <a className="btn line" onClick={(): Promise<void> => handleGoToOnfido()}>
            {modal.continue}
          </a>
          <a className="btn btn--bordered" onClick={(): void => setInfoModalVisible(false)}>
            {modal.goBack}
          </a>
        </div>
      </Modal>
    </>
  );
});
