import { action, makeAutoObservable, when } from 'mobx';
import { StepInterface, steps } from '../constants/steps';
import { PageInterface, pages } from '../constants/pages';
import { ErrorInterface, errors } from '../constants/errors';
import { handleKeepAlive } from '../methods/handleKeepAlive';
import { handleFinishLater } from '../methods/handleFinishLater';
import { flowDefault, flowRemediation } from '@s/common/defaults/clientConfigDefaults';

export class AppStateStore {
  rootStore;
  currentScreen: PageInterface = pages.pageBankSelection;
  currentStep: StepInterface = steps[1];
  steps: StepInterface[] = steps;
  showSteps = true;
  error: ErrorInterface = errors.errorDefault;
  activePage = '';

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    // setScreenError
    when(
      () =>
        this.activePage != 'bank-select' &&
        this.activePage != 'onfido' &&
        this.activePage != 'onfido-no-loader' &&
        this.activePage != 'onfido-complete' &&
        this.activePage != '',
      () => {
        if (this.currentScreen.subpage !== 4) {
          this.setError(errors.errorDefault);
          this.changePage('error', 1);
        }
      },
    );

    // setExpiredError
    when(
      () => this.activePage === 'session-expired' || this.activePage === 'error-auth',
      () => {
        if (this.rootStore.ClientState.flow === flowRemediation) {
          this.setError(errors.errorExpiredRem);
        } else {
          this.setError(errors.errorExpired);
        }

        this.changePage('error', 1);
      },
    );

    // setCookieError
    when(
      () => this.activePage === 'error-missing-cookie-on-redirect-from-oidc',
      () => {
        if (this.rootStore.ClientState.flow === flowRemediation) {
          this.setError(errors.errorMissingCookieRem);
        } else {
          this.setError(errors.errorMissingCookie);
        }

        this.changePage('error', 1);
      },
    );

    // setScreenBankSelection
    when(
      () => this.activePage === 'bank-select',
      () => {
        this.changePage('bank', 1);
        this.changeStep(steps[1]);
        this.setSteps(steps);
      },
    );

    // setScreenOnfido
    when(
      () => this.activePage === 'onfido',
      () => {
        this.changePage('uploadid', 1);
        this.changeStep(steps[2]);
        this.setSteps([steps[0], { ...steps[1], status: 'done' }, { ...steps[2], status: '' }]);
      },
    );

    // setScreenOnfidoNoLoader
    when(
      () => this.activePage === 'onfido-no-loader',
      () => {
        this.rootStore.AppConfig.setOnfidoShowLoading(false);
        this.changePage('uploadid', 1);
        this.changeStep(steps[2]);
        this.setSteps([steps[0], { ...steps[1], status: 'done' }, { ...steps[2], status: '' }]);
      },
    );

    // setScreenOnfidoComplete
    when(
      () => this.activePage === 'onfido-complete',
      () => {
        this.changePage('uploadid', 4);
        this.changeStep(steps[2]);
        this.setSteps([
          { ...steps[0], status: 'done' },
          { ...steps[1], status: 'done' },
          { ...steps[2], status: 'done' },
        ]);
      },
    );

    // setScreenContinue
    when(
      () => this.activePage === 'continue',
      () => {
        this.changePage('continue', 1);
        this.setShowSteps(false);
      },
    );

    // setStepsVisibleNotContinue
    when(
      () => this.currentScreen.page !== 'continue',
      () => {
        this.setShowSteps(true);
      },
    );

    // setStepsVisibileNotError
    when(
      () => this.currentScreen.page !== 'error',
      () => {
        this.setShowSteps(true);
      },
    );

    // setStepsHiddenContinue
    when(
      () => this.currentScreen.page === 'continue',
      async () => {
        try {
          await handleFinishLater();
          this.setShowSteps(false);
        } catch (e) {
          this.changePage('error', 1);
        }
      },
    );

    // setStepsHiddenError
    when(
      () => this.currentScreen.page === 'error',
      () => {
        this.setShowSteps(false);
      },
    );
  }

  @action.bound changePage(page: string, subpage: number): void {
    this.rootStore.ClientState.flow === flowDefault && subpage !== 4 && handleKeepAlive();
    this.currentScreen = { page, subpage, isLoaded: true };
  }

  @action.bound changeStep(step: StepInterface): void {
    this.currentStep = step;
  }

  @action.bound setSteps(newSteps: StepInterface[]): void {
    this.steps = newSteps;
  }

  @action.bound setShowSteps(showSteps: boolean): void {
    this.showSteps = showSteps;
  }

  @action.bound setError(error: ErrorInterface): void {
    this.error = error;
  }

  @action.bound setActivePage(activePage: string): void {
    this.activePage = activePage;
  }
}
