import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import * as rax from 'retry-axios';
import { observer } from 'mobx-react';
import { StoreContext } from '../../App';
import { countries } from '../../../constants/countries';
import { axiosConfig } from '../../../constants/axiosConfig';
import { getApiOrigin } from '../../../methods/getApiOrigin';
import { SuggestInput } from '../../suggestInput/component';
import { flowDefault, flowRemediation } from '@s/common/defaults/clientConfigDefaults';
import { default as onfidoLogo } from '../../../assets/onfido_logo.svg';

const defaultSuggest = {
  name: 'Nederland',
  id: 'NL',
  documentTypes: {
    passport: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    driving_licence: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    national_identity_card: true,
  },
};

export const CountrySelect: React.FC = observer(() => {
  const onfido = (window as any).Onfido;
  const store = useContext(StoreContext);
  const { translations } = store.AppConfig;
  const { selectedCountry, flow, sdkToken, onfidoNew } = store.ClientState;

  const { continueLabel } = translations;
  const { heading, searchCountry, suggestedCountry, pleaseWait, pleaseWaitLonger } = translations.pageCountrySelect;

  const [spinnerText, setSpinnerText] = useState(pleaseWait);

  const axiosInstance = axios.create();
  axiosInstance.defaults.raxConfig = {
    ...axiosConfig(axiosInstance),
    onRetryAttempt: (err): void => {
      const cfg = rax.getConfig(err);
      if (cfg.currentRetryAttempt === 5) {
        store.AppState.changePage('error', 1);
      }
    },
  };
  const interceptorId = rax.attach(axiosInstance);

  const getOnfidoToken = async (): Promise<string> => {
    try {
      const response = await axiosInstance.request({
        method: 'GET',
        url: `${getApiOrigin()}/onfido/token`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      });

      return response.data.onfidoSdkToken;
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnfidoToken = async (): Promise<void> => {
    try {
      const token = await getOnfidoToken();

      if (token && token.length) {
        store.ClientState.setSdkToken(token);
      }
    } catch (e) {
      store.AppState.changePage('error', 1);
      console.log(e);
    }
  };

  const handlePageChange = (event: React.MouseEvent): void => {
    event.preventDefault();
    store.AppState.changePage('uploadid', 3);
  };

  const validateCurrentSelection = (): boolean => {
    const validator = countries.filter((country) => country.id === selectedCountry);
    return !!validator.length;
  };

  useEffect(() => {
    validateCurrentSelection();
  }, [selectedCountry]);

  useEffect(() => {
    store.ClientState.flow === flowRemediation ? handleOnfidoToken() : '';

    // setTimeout(() => {
    //   setSpinnerText(pleaseWaitLonger)
    //   clearTimeout(this)
    // }, 30000)
  }, []);

  return (
    <div className="page page--country-select">
      {flow !== flowDefault ? (
        <div className="onfido-logo--container">
          <img className="onfido-logo--image" src={onfidoLogo} />
        </div>
      ) : (
        ''
      )}
      <div>
        <h1>{onfidoNew && onfidoNew === '1' ? 'Zoek het land waar uw identiteitsbewijs is verstrekt.' : heading}</h1>
        <div className="country-select--wrapper">
          <SuggestInput
            values={countries}
            onChange={(countryId: string): void => store.ClientState.setSelectedCountry(countryId)}
            defaultSuggest={defaultSuggest}
            placeholder={searchCountry}
            suggestLabel={suggestedCountry}
          />
          {onfido === undefined || !sdkToken ? (
            <div className="onfido-token__holder">
              <div className="onfido-token-spinner" />
              {/* <div className="redirect-info">{spinnerText}</div> */}
            </div>
          ) : (
            <div className="liner centered">
              <button className="btn" onClick={handlePageChange} disabled={!validateCurrentSelection()}>
                {continueLabel}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
