import React, { Fragment, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { StoreContext } from '../App';
import { flowDefault } from '@s/common/defaults/clientConfigDefaults';
import './styles.scss';

export const Help: React.FC = observer(() => {
  const store = useContext(StoreContext);

  const { activePage, error } = store.AppState;
  const { translations } = store.AppConfig;
  const { pcn, clic, flow } = store.ClientState;

  const [showPrint, setShowPrint] = useState(false);

  function helpContent() {
    return { __html: error.type === 'error-cookie' ? translations.cookieText : translations.helpText };
  }

  useEffect(() => {
    if (activePage === 'onfido-complete') {
      setShowPrint(true);
    }
  }, [activePage]);

  return (
    <div className="help">
      <div className="help--inner" dangerouslySetInnerHTML={helpContent()} />
      {pcn !== '' ? pcn : ''} {flow === flowDefault && clic !== '' ? `(CLIC) ${clic}` : ''}{' '}
      {showPrint ? (
        <a href="#" onClick={(): void => window.print()}>
          {translations.print}
        </a>
      ) : (
        ''
      )}
    </div>
  );
});
